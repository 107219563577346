import { Controller } from "@hotwired/stimulus";
import Glide from "@glidejs/glide";

// Connects to data-controller="glide"
export default class extends Controller {
  connect() {
    this.setUpGlide();
    window.addEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    window.glide.destroy();
    this.setUpGlide();
  };

  setUpGlide = () => {
    let perView = 1;
    if (window.innerWidth > 1600) {
      perView = 3;
    } else if (window.innerWidth > 1000) {
      perView = 2;
    }
    let opts = {
      type: "carousel",
      startAt: 0,
      perView,
      autoplay: 2500,
    };
    window.glide = new Glide(".glide", { ...opts }).mount();
  };
}
