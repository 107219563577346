import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";

// Connects to data-controller="select-commodity"
export default class extends Controller {
  static targets = ["timelineWrapper", "timeline", "commodity"];

  connect() {
    console.log("Hello from select-commodity controller", this.element);
  }

  async changeTimeline(event) {
    this.plot();
  }

  async changeCommodity(event) {
    this.timelineWrapperTarget.classList.remove("is-hidden");
    this.plot();
  }

  async plot() {
    document.querySelector("#priceTrendChart").classList.remove("is-hidden");
    let commodity = this.commodityTarget.value;
    let body = JSON.stringify({ commodity });
    let days = Number.parseInt(this.timelineTarget.value);

    let response = await fetch(`/dashboard/chart`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    let res = await response.json();
    console.log("Response ==>", res);

    let data = res.data;
    let unit = res.unit;

    const ctx = document.getElementById("priceTrendChart");

    let chartStatus = Chart.getChart("priceTrendChart");
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    new Chart(ctx, {
      type: "line",
      data: {
        labels: Object.keys(data).reverse().splice(0, days).reverse(),
        datasets: [
          {
            data: Object.keys(data)
              .map((key) => data[key])
              .reverse()
              .splice(0, days)
              .reverse(),
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `${commodity} Price Trend (in ${unit})`,
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }
}
