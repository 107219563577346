import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";

// Connects to data-controller="select-stock"
export default class extends Controller {
  static targets = ["timelineWrapper", "timeline", "stock", "exchange"];

  connect() {
    console.log("Hello from select-stock controller", this.element);
  }

  async changeTimeline(event) {
    this.plot();
  }

  async changeStock(event) {
    this.timelineWrapperTarget.classList.remove("is-hidden");
    this.plot();
  }

  async plot() {
    document.querySelector("#stockTrendChart").classList.remove("is-hidden");
    let stock = this.stockTarget.value;
    let body = JSON.stringify({
      name: stock,
      exchange: this.exchangeTarget.value,
    });
    let days = Number.parseInt(this.timelineTarget.value);

    let response = await fetch(`/stocks/chart`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    let data = await response.json();
    console.log("Response ==>", data);

    const ctx = document.getElementById("stockTrendChart");

    let chartStatus = Chart.getChart("stockTrendChart");
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    new Chart(ctx, {
      type: "line",
      data: {
        labels: Object.keys(data).reverse().splice(0, days).reverse(),
        datasets: [
          {
            data: Object.keys(data)
              .map((key) => data[key])
              .reverse()
              .splice(0, days)
              .reverse(),
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `${stock} Stock Trend`,
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }
}
