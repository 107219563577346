import { Controller } from "@hotwired/stimulus";
import truncate from "truncate";

// Connects to data-controller="upload"
export default class extends Controller {
  static targets = ["label", "preview"];

  connect() {
    console.log("upload controller");
  }

  handleFileChange(e) {
    let file = e.target.files[0];
    let name = truncate(file.name, 15);
    this.labelTarget.innerText = name;
    this.previewTarget.classList.toggle("is-hidden");
    let fileUrl = URL.createObjectURL(file);
    this.previewTarget.setAttribute("href", fileUrl);
    this.previewTarget.innerText = name;
  }
}
