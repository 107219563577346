import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bulma"
export default class extends Controller {
  static targets = ["navbarMenu"];

  connect() {
    (document.querySelectorAll(".notification .delete") || []).forEach(
      ($delete) => {
        const $notification = $delete.parentNode;

        $delete.addEventListener("click", () => {
          $notification.parentNode.removeChild($notification);
        });
      }
    );
  }

  toggleNavbar(e) {
    e.target.classList.toggle("is-active");
    this.navbarMenuTarget.classList.toggle("is-active");
  }
}
